
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormInputText from "@/components/form/InputText.vue";
import CFormTextarea from "@/components/form/Textarea.vue";
import PProgressBar from "primevue/progressbar";
import PMultiSelect from "primevue/multiselect";
import PAutoComplete from "primevue/autocomplete";
import CFormImageUpload from "@/components/form/ImageUpload.vue";
import CFormSelect from "@/components/form/Select.vue";

import { isEditFormByRouteName } from "@/libs/utils";
import { formatCNPJ, formatPhone, formatZipCode } from "@/libs/utils";
import { removeCNPJMask, removeFormatZipCode, removePhoneMask } from "@/libs/utils";

import { LocalitySearch } from "@/store/locality/types";
import { EstablishmentForm } from "@/store/establishment/types";
import { Procedure } from "@/store/procedure/types";

const VEstablishmentForm = defineComponent({
  name: "VEstablishmentForm",
  components: {
    CModal,
    CFormInputText,
    CFormTextarea,
    PProgressBar,
    PMultiSelect,
    CFormImageUpload,
    CFormSelect,
    PAutoComplete,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id);
    const isEditForm = isEditFormByRouteName(route.name);

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} clínica | obmed` });

    const establishment = computed(() => store.state.establishment.current);
    const specialties = computed(() => store.state.specialty.all);
    const procedures = computed(() => store.state.procedure.searchList);
    const photo = ref<{ photo: string; file?: File }[]>([]);
    const photos = ref<{ photo: string }[]>([]);
    const banks = computed(() => store.state.bank.all);

    const form = reactive<EstablishmentForm>({
      cd_banco: null,
      nr_conta: "",
      nr_agencia: "",
      nr_operacao: "",
      nr_chave_pix: "",
      ds_complemento: "",
      ds_email: "",
      nm_bairro: "",
      nm_fantasia: "",
      nm_razao_social: "",
      nm_responsavel_tecnico: "",
      nm_rua: "",
      nr_cep: "",
      nr_cnpj: "",
      nr_estabelecimento: "",
      nr_telefone: "",
      nr_whatsapp: "",
    });
    const loading = reactive({
      establishment: false,
      submit: false,
      specialties: false,
      procedures: false,
      zipCode: false,
    });
    const zipCode = ref<{ inputText: HTMLInputElement } | null>(null);
    const cep = ref<LocalitySearch | null>();
    const selectedSpecialties = ref<number[]>([]);
    const selectedProcedures = ref<Procedure[]>([]);

    function handleClose() {
      router.back();
    }

    function validate(form: { [key: string]: any }) {
      if (form.nr_telefone.length < 10) {
        store.commit("setToast", [{ summary: "Número de telefone inválido!", severity: "error" }]);
        return false;
      }

      return true;
    }

    async function searchProcedures({ query }: any) {
      loading.procedures = true;
      await store.dispatch("searchProcedures", {
        search: { nm_procedimento: query?.nm_procedimento || query },
        hasPagination: Boolean(query),
      });
      loading.procedures = false;
    }

    async function onSubmit() {
      if (!validate(form)) return;

      const nr_telefone = `+55${form.nr_telefone}`;

      const currentSpecialties = establishment.value?.especialidades?.map((item) => item.cd_especialidade) || [];
      const addedSpecialties = selectedSpecialties.value.filter((_id) => !currentSpecialties.includes(_id));
      const removedSpecialties = currentSpecialties.filter((_id) => !selectedSpecialties.value.includes(_id));

      loading.submit = true;

      const nr_cep = removeFormatZipCode(form.nr_cep);
      const response = isEditForm
        ? await store.dispatch("updateEstablishment", { _id, form: { ...form, nr_cep, nr_telefone } })
        : await store.dispatch("createEstablishment", {
            form: {
              ...form,
              ...{ nr_cep, nr_telefone },
              ...{ especialidades: addedSpecialties, procedimentos: selectedProcedures.value.map((item) => item.id) },
            },
          });

      if ((response?.status || 500) < 300 && photo.value?.[0]?.file)
        await store.dispatch("updateEstablishmentPhoto", {
          _id: _id || response?.data.id,
          aq_foto: photo.value[0].file,
        });

      if (!response?.data.id) {
        loading.submit = false;
        return;
      }

      if (isEditForm) {
        await Promise.allSettled([
          addedSpecialties.map((_id) =>
            store.dispatch("addEstablishmentSpecialty", {
              form: { cd_especialidade: _id, cd_estabelecimento: response.data.id },
            })
          ),
        ]);
        await Promise.allSettled([
          removedSpecialties.map((_id) =>
            store.dispatch("removeEstablishmentSpecialty", { _id, cd_estabelecimento: Number(establishment.value?.id) })
          ),
        ]);
      }

      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.dispatch("getEstablishments");
        router.replace({ name: "establishment-list" });
      }
    }

    function autocompleteAddressForm() {
      form.nm_rua = cep.value?.logradouro || "";
      form.nm_bairro = cep.value?.bairro || "";
      form.ds_complemento = cep.value?.complemento || "";
    }

    async function handleAddressForm(value: string) {
      value = formatZipCode(value);

      if (form.nr_cep.length < 10 || value !== form.nr_cep) {
        form.nr_cep = value;

        if (zipCode.value?.inputText) zipCode.value.inputText.value = form.nr_cep;
        cep.value = null;

        return;
      }

      loading.zipCode = true;
      const response = await store.dispatch("searchCEP", { cep: removeFormatZipCode(form.nr_cep) });
      cep.value = response?.data || null;
      if (cep.value) autocompleteAddressForm();
      loading.zipCode = false;
    }

    async function getAllSpecialty() {
      loading.specialties = true;
      await store.dispatch("getAllSpecialty");
      loading.specialties = false;
    }

    async function getEstablishment() {
      loading.establishment = true;
      await Promise.allSettled([store.dispatch("getEstablishment", { _id }), getAllSpecialty()]);
      loading.establishment = false;
    }

    async function handleApplyForm() {
      await getEstablishment();

      form.nr_agencia = establishment.value?.nr_agencia || "";
      form.nr_chave_pix = establishment.value?.nr_chave_pix || "";
      form.nr_conta = establishment.value?.nr_conta || "";
      form.nr_operacao = establishment.value?.nr_operacao || "";
      form.cd_banco = establishment.value?.cd_banco?.id || null;
      form.ds_complemento = establishment.value?.ds_complemento || "";
      form.ds_email = establishment.value?.ds_email || "";
      form.nm_bairro = establishment.value?.nm_bairro || "";
      form.nm_fantasia = establishment.value?.nm_fantasia || "";
      form.nm_razao_social = establishment.value?.nm_razao_social || "";
      form.nm_responsavel_tecnico = establishment.value?.nm_responsavel_tecnico || "";
      form.nm_rua = establishment.value?.nm_rua || "";
      form.nr_cep = establishment.value?.nr_cep || "";
      form.nr_cnpj = establishment.value?.nr_cnpj || "";
      form.nr_estabelecimento = establishment.value?.nr_estabelecimento || "";
      form.nr_telefone = establishment.value?.nr_telefone.substring(3, 14) || "";
      form.nr_whatsapp = establishment.value?.nr_whatsapp || "";

      if (establishment.value?.aq_foto) photos.value = [{ photo: establishment.value?.aq_foto }];

      selectedSpecialties.value = establishment.value?.especialidades?.map((item) => item.cd_especialidade) || [];
    }

    watch(() => form.nr_cep, handleAddressForm);

    if (!banks.value.length) store.dispatch("getAllBanks");
    if (isEditForm) handleApplyForm();
    else {
      getAllSpecialty();
    }

    return {
      ...{ form, loading, isEditForm, specialties, procedures, zipCode, banks },
      ...{ selectedSpecialties, selectedProcedures, photo, photos },
      ...{ handleClose, onSubmit, searchProcedures },
      ...{ formatCNPJ, removeCNPJMask, formatPhone, removePhoneMask },
    };
  },
});

export default VEstablishmentForm;
